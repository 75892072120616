import dayjs from 'dayjs';

export function formatDate(date?: Date | string): string {
  return date ? dayjs(date).format('DD-MM-YYYY') : 'N/A';
}

export function stringDateToISOString(date?: string): string {
  if (!date) {
    return '';
  }
  return new Date(date.split('-').reverse().join('-')).toISOString();
}