import { AnswerType } from '../vo';

type NumberQuestionResult = QuestionResult<number>;
type BooleanQuestionResult = QuestionResult<boolean>;
type InputQuestionResult = QuestionResult<string>;

interface QuestionResultYes<T> {
  answer: AnswerType.YES;
  details: T; 
}

interface QuestionResultNoOrNotSure {
  answer: Exclude<AnswerType, AnswerType.YES>; 
  details?: never; 
}

type QuestionResult<T> = QuestionResultYes<T> | QuestionResultNoOrNotSure;

export const getQuestionResult = <T>(
  answer: AnswerType,
  details?: T
): QuestionResult<T> => {
  if (answer === AnswerType.YES && (details !== null && details !== undefined)) {
    return { answer, details };
  }
  
  if (answer === AnswerType.NO || answer === AnswerType.NOT_SURE) {
    return { answer };
  }

  throw new Error(`getQuestionResult: Not Mapped, contact developers, ${JSON.stringify(answer)}, ${JSON.stringify(details)}`);
};


export const getInputQuestionResult = (
  answer: AnswerType,
  details?: string
): InputQuestionResult => getQuestionResult(answer, details);

export const getNumberQuestionResult = (
  answer: AnswerType,
  details?: number
): NumberQuestionResult => getQuestionResult(answer, details);

export const getBooleanQuestionResult = (
  answer: AnswerType,
  details?: boolean
): BooleanQuestionResult => getQuestionResult(answer, details);
