import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import da from './locales/da/common.json';
import integrations from './locales/da/integrations.json';

i18n.use(initReactI18next).init({
  lng: 'da',
  resources: {
    da: {
      translation: {
        ...da,
        ...integrations
      },
    },
  },
  supportedLngs: ['da'],
  fallbackLng: ['da'],
});

export default i18n;