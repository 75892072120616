import { useGo } from '@refinedev/core';
import { Flex, Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';

export const ForbiddenError = () => {
  const { t } = useTranslation();
  const go = useGo();

  return (
    <Flex vertical align="center">
      <Result
        status="error"
        title={t('global.error')}
        subTitle={t('page.acceptPrice.forbiddenErrorStatus')}
      />
      <Button type="primary" size="large" onClick={() => go({ to: '/auth' })}>
        {t('page.auth.forgotPassword.backToLogin')}
      </Button>
    </Flex>
  );
};
