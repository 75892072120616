import React from 'react';

export const allowOnlyNumbers = (e: React.KeyboardEvent) => {
  const specialCharRegex = new RegExp("[0-9]");

  if (!specialCharRegex.test(e.key)) {
    e.preventDefault();
    return false;
  }
};

export const addTrailingZerosToStart = (value: string, requiredLength: number) => {
  if (value.length < requiredLength) {
    return '0'.repeat(requiredLength - value.length) + value;
  }

  return value;
}