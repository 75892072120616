import { ModalForm, ProFormCheckbox } from '@ant-design/pro-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreate } from '@refinedev/core';
import { Button, Flex, Form, Typography } from 'antd';
import { PartnerSubmissionPortalRouteParams } from '../../pages/partner/vo/route-params';

const { Title } = Typography;

export const AcceptPriceForm: React.FC<
  PartnerSubmissionPortalRouteParams &
  {
    onSuccess?: () => Promise<unknown>;
  }
> = ({
       organisationId,
       id,
       onSuccess,
     }) => {

  const { mutateAsync, isLoading } = useCreate();

  const { t } = useTranslation();

  return <ModalForm
    trigger={
      <Button shape={'round'} type={'primary'}>{t('page.partnerPortal.table.acceptPriceButton')}</Button>
    }
    onFinish={async () => {
      await mutateAsync({
        resource: 'partner-deals/' + organisationId + '/' + id + '/accept-price',
        values: {},
      });
      onSuccess && await onSuccess();
      return true;
    }}
    submitter={{
      submitButtonProps: false,
      resetButtonProps: false,
      render: () => [],
    }}
    style={{ width: '100%', margin: '0 auto', padding: 24 }}
  >
    <Title level={1}>{t(`component.acceptPrice.title`)}</Title>
    <Title level={4}>{t(`component.acceptPrice.description`)}</Title>

    <ProFormCheckbox.Group
      name="checkbox"
      layout="vertical"
      label="Vilkår og betingelser"
      options={['Jeg accepterer, at Aksel.nu sætter bilen til salg og accepterer samtidig Aksel.nu’s vilkår']}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />

    <Form.Item>
      <Flex justify={'flex-end'}>
        <Button size={'large'} loading={isLoading} htmlType={'submit'} type={'primary'}
                shape={'round'}>Sæt til salg</Button>
      </Flex>
    </Form.Item>


  </ModalForm>
};