import { ProForm, ProFormInstance } from '@ant-design/pro-components';
import { useCreate, useNotification } from '@refinedev/core';
import { Button, Divider, Flex, Form, Progress, Typography } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../hooks';
import {
  DealStatus,
  FileUpload,
  getStatusOrder,
} from '../../../pages/partner/vo/submission';
import { Deal } from '../../../utils/deal-base.dto';
import { IFileUploaderProps } from '../../file-uploader';
import { FormDetails } from '../form-details';
import { CarQuestionnaire } from './car-questionnaire';
import { CarImageGuides } from './guides';
import { getMotorQuestionResult } from './helpers/get-motor-question-result';
import {
  getBooleanQuestionResult,
  getInputQuestionResult,
} from './helpers/get-question-result';
import { mapToAnswerType } from './helpers/map-to-answer-type';

const { Title, Text } = Typography;

type UploadImagesFormProps = {
  deal: Deal | undefined;
  admin: boolean;
  onComplete?: () => Promise<unknown>;
};

const UPLOAD_IMAGES_RESOURCE = 'deal/provide-images';

export const UploadImagesForm = ({ deal, admin, onComplete = async () => {} }: UploadImagesFormProps) => {
  const { open } = useNotification()
  const fileUploader = useRef<IFileUploaderProps>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [uploadingFilesPercentage, setUploadingFilesPercentage] = useState(0);
  const formRef = useRef<ProFormInstance>();
  const [form] = Form.useForm();
  const { mutateAsync, isLoading, isSuccess } = useCreate({
    mutationOptions: {
      onSettled() {
        setIsLoadingData(false);
      },
    },
  });

  const isCarDataPresent =
    getStatusOrder(deal?.status) >
    getStatusOrder(DealStatus.WAITING_FOR_IMAGES);

  const { isMobileWithTablet } = useIsMobile();

  const { t } = useTranslation();

  const onFinish = async (values: any) => {
    const currentUploader = fileUploader.current;

    if (!currentUploader) {
      throw new Error('File uploader is not initialized');
    }

    setIsLoadingData(true);

    let files = currentUploader.getFiles();

    while (true) {
      files = currentUploader.getFiles();
      const uploadingPercentage = files.map(f => f.progress?.percentage || 0).reduce((a, b) => a + b, 0) / files.length;
      setUploadingFilesPercentage(Math.floor(uploadingPercentage));

      // has no more uploading
      if (!files.find(f => !f.progress?.uploadComplete)) {
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, 300));
    }


    const motor = getMotorQuestionResult(
      mapToAnswerType(values.motor),
      values.engineReplacementType,
    );
    const repainted = getInputQuestionResult(
      mapToAnswerType(values.repainted),
      values.repaintedAnswer,
    );
    const damaged = getInputQuestionResult(
      mapToAnswerType(values.damaged),
      values.damagedAnswer,
    );
    const chipTuned = {
      answer: mapToAnswerType(values.chipTuned),
      details:
        values.chipTuningDocumentation === 'dontKnow'
          ? null
          : values.chipTuningDocumentation
    }

    const timeBeltChanged = getBooleanQuestionResult(
      mapToAnswerType(values.timingBeltChanged),
      values.timingBeltChangedAnswer,
    );
    const usageDetails = {
      motor,
      repainted,
      damaged,
      chipTuned,
      timeBeltChanged,
      serviceInTime: values.serviceDocumentation,
      tires: values.tires,
    };


    const images: FileUpload[] = files.map(item => {
      const meta = item.meta;
      const data = item.data;

      return {
        size: data.size,
        originalName: meta.name,
        type: data.type,
        urlKey: String(meta.key),
      };
    });

    const body = {
      id: deal?.id,
      usageDetails,
      images,
    };

    await mutateAsync({
      resource: UPLOAD_IMAGES_RESOURCE,
      values: {
        ...body,
      },
    });

    await onComplete();
  };

  return (
    <>
      {isCarDataPresent || isSuccess ? (
        <FormDetails deal={deal} dealId={deal?.id} />
      ) : (
        !admin && <>
          <Flex style={{ padding: '24px 0' }} vertical>
            <Flex align="center" vertical>
              <Title level={3}>Tag billeder, der sælger</Title>
              <Text>
                Næst efter prisen er billederne den vigtigste faktor, når du
                skal sælge din bil.
              </Text>
              <Text>
                Det kan derfor godt betale sig at gøre sig umage når billederne
                skal tages.
              </Text>
              <Text style={{ marginBottom: '12px' }}>
                Vi har lavet denne guide, der hjælper dig igennem billede for
                billede, så din bil kommer til at vise sig fra sin bedste side.
              </Text>
              <Text>
                Der er i alt <strong>13 vinkler og punkter</strong> vi skal have
                taget billeder af.
              </Text>
              <Divider />
            </Flex>
            <ProForm
              form={form}
              formRef={formRef}
              autoFocusFirstInput
              onFinish={onFinish}
              onSubmitCapture={async () => {
                try {
                  await form.validateFields();
                } catch (err) {
                  try {
                    const errorFields = (err as unknown as Record<string, object>).errorFields as Array<{name: string}>;
                    const errorField = errorFields[0];
                    open?.({
                      type: 'error',
                      message: `Der mangler oplysninger i et eller flere påkrævede felter. Udfyld venligst alle felter markeret med *. ${errorFields.length} felter er ikke udfyldt`
                    });

                    form.scrollToField(errorField?.name as string);
                  } catch (e) {
                    console.error(e)
                  }

                }

              }}
              submitter={{
                submitButtonProps: false,
                resetButtonProps: false,
                render: () => [],
              }}
              style={{
                maxWidth: 820,
                margin: isMobileWithTablet ? '0' : '0 auto',
                padding: 0,
              }}
            >
              <CarImageGuides
                ref={fileUploader}
                formRef={formRef}
                isLoading={isLoading}
                dealId={deal?.id}
              />

              <CarQuestionnaire />

              <Flex justify="center" align={'center'} gap={'small'}>
                {isLoadingData && <Progress size={32} type="circle" percent={uploadingFilesPercentage} />}

                <Button
                  loading={isLoading || isLoadingData}
                  disabled={isLoading || isLoadingData}
                  type="primary"
                  htmlType="submit"
                >
                  {t('global.send')}
                </Button>
              </Flex>
            </ProForm>
          </Flex>
        </>
      )}
    </>
  );
};
