import { ProForm } from '@ant-design/pro-components';
import { HttpError } from '@refinedev/core';
import { AuthActionResponse } from '@refinedev/core/dist/contexts/auth/types';
import { UseMutateFunction } from '@tanstack/react-query';
import { Button, Flex, Form, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { LoginParams } from '../../provider/auth/vo/signin-response.interface';
import { SignUpParams } from '../../provider/auth/vo/signup-response.interface';
import { Client } from '../../types/client';
import { UserProfileType } from '../../types/user';
import { SignupForm } from '../auth/signup/signup.form';

const { Title } = Typography;
const HTTP_STATUS_CONFLICT_CODE = 409;
const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;

type AcceptPriceSignupFormProps = {
  signUpMutate: UseMutateFunction<
    AuthActionResponse,
    HttpError | Error,
    SignUpParams,
    unknown
  >;
  isLoadingSignup: boolean;
  signupButtonMessage?: string;
  clientInfo?: Client;
};

export const AcceptPriceSignupForm = ({
  clientInfo,
  signUpMutate,
  isLoadingSignup,
  signupButtonMessage = 'page.acceptPrice.signupSubmit'
}: AcceptPriceSignupFormProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const onFinish = async (values: UserProfileType & LoginParams) => {
    try {
      signUpMutate({ ...values, email: clientInfo?.email ?? values.email });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === HTTP_STATUS_CONFLICT_CODE) {
        form.setFields([
          {
            name: 'email',
            errors: [t('page.auth.registration.fields.email.alreadyExists')],
          },
        ]);
      } else if (
        axiosError.response?.status === HTTP_STATUS_UNPROCESSABLE_ENTITY
      ) {
        form.setFields([
          {
            name: 'email',
            errors: [
              t('page.auth.registration.fields.email.notRegisteredInPipedrive'),
            ],
          },
        ]);
      }
    }
  };

  return (
    <ProForm
      onFinish={onFinish}
      initialValues={{
        firstName: clientInfo?.name?.split(' ')[0],
        lastName: clientInfo?.name?.split(' ')?.slice(-1)[0],
        ...clientInfo,
      }}
      submitter={{
        submitButtonProps: false,
        resetButtonProps: false,
        render: () => [],
      }}
      style={{
        maxWidth: 820,
        width: '100%',
        margin: '0 auto',
        padding: 24,
      }}
    >
      <Flex justify="center">
        <Title level={2}>{t('page.auth.registration.welcomeTitle')}</Title>
      </Flex>
      <SignupForm />
      <Flex justify="center">
        <Button loading={isLoadingSignup} type="primary" htmlType="submit">
          {t(signupButtonMessage)}
        </Button>
      </Flex>
    </ProForm>
  );
};
