import type { RefineThemedLayoutV2Props } from '@refinedev/antd';
import { Layout as AntdLayout } from 'antd';
import React from 'react';
import styled from "styled-components";
import HeaderIcon from "../../assets/header-icon.svg";
import {FacebookOutlined, LinkedinOutlined} from "@ant-design/icons";
import { Container } from "../header";

const { Footer: AntdFooter } = AntdLayout;

export const Footer: React.FC<RefineThemedLayoutV2Props> = () => {
    return (
        <StyledFooter>
            <Container>
                <Content>
                    <Cell>
                        <a href="https://aksel.nu" target={"_blank"} title="Aksel" className="site-logo-link">
                            <img src={HeaderIcon} alt="Aksel" className="site-link"/>
                        </a>
                    </Cell>
                    <Cell>
                        Grønlandsvej 28D, 8700 Horsens{" | "}
                        <a href="tel:+4552516386">+4552516386</a>
                        {" | "}
                        <a href="mailto:hej@aksel.nu">hej@aksel.nu</a>
                    </Cell>
                    <Cell>CVR: 44057778</Cell>
                    <Cell>
                        <a href="https://www.linkedin.com/company/aksel-nu/" target="_blank" className="icon-link">
                            <LinkedinOutlined/>
                        </a>
                        <a href="https://www.facebook.com/aksel.nu/" target="_blank" className="icon-link right-icon-link">
                            <FacebookOutlined/>
                        </a>
                    </Cell>
                </Content>
            </Container>
        </StyledFooter>
    );
};


const StyledFooter = styled(AntdFooter)`
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    position: relative;
    color: #fff;
    background: #264c59;
    overflow: hidden;    

    @media print,screen and (width >= 40em) {
        padding-top: 70px;
        padding-bottom: 70px
    }

    @media print,screen and (width >= 64em) {
       padding-top: 90px;
       padding-bottom: 90px
    }

    @media screen and (width >= 75em) {
       padding-top: 120px;
       padding-bottom: 120px
    }

    @media print,screen and (width <= 39.9em) {
       text-align: center
    }
    `;

const Content = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-top: -.625rem;
    margin-bottom: -.625rem;

    @media print,screen and (width >= 40em) {
       margin-top:-.9375rem;
       margin-bottom: -.9375rem
    }
    `;

const Cell = styled.div`
    height: calc(100% - 1.875rem);
    width: calc(100% - 1.875rem);
    margin: 15px;
    font-size: 14px;

    a {
        color: inherit;
    }

    a:hover {
        color: #5cb1c8;
    }

    @media print,screen and (width >= 40em) {
       font-size: 15.2px
    }

    @media print,screen and (width >= 64em) {
       font-size:16px
    }
    
    .icon-link {
        font-size: 25px;
    }

    .right-icon-link {
        margin-left: 15px;        
    }

    .site-logo-link {
        .site-link {
            width: 120px;
            transition: all .6s;

            @media print,screen and (width >= 40em) {
                width: 120px
            }

            @media print,screen and (width >= 64em) {
                width: 144px
            }

            @media screen and (width >= 75em) {
                width: 144px;
            }
        }
    }
    `;

