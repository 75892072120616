import { CarOutlined } from '@ant-design/icons';
import { useToken } from '@ant-design/pro-components';
import { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { useCreate, useCustom, useParsed, useRegister } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Alert, Button, Divider, Flex, Form, Result, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  AcceptPriceClosedForAcceptanceError,
  AcceptPriceError,
  ForbiddenError,
  AcceptPriceGeneralForm,
  AcceptPriceSignupForm,
} from '../../components/accept-price';
import { CarInfo } from '../../components/car-info';
import { LoadingComponent } from '../../components/shared/loading';
import { useAuthContext } from '../../contexts/auth';
import { SignUpParams } from '../../provider/auth/vo/signup-response.interface';
import { UserDto } from '../../provider/auth/vo/user.dto';
import { ErrorComponent } from '../error/404';
import { DealStatus } from '../partner/vo/submission';
import { GetAcceptPriceResponse } from './vo/get-accept-price.response';
import { AcceptPriceRouteParams } from './vo/route-params';
import { AcceptPriceExpiredError } from '../../components/accept-price/errors/accept-price-expired';

const { Text, Title } = Typography;

export const AcceptPricePage: React.FC<
  RefineThemedLayoutV2HeaderProps
> = () => {
  useDocumentTitle({ i18nKey: 'page.acceptPrice.pageMetaTitle' });

  const { t } = useTranslation();
  const { token } = useToken();
  const [successSignup, setSuccessSignup] = useState(false);

  const { user } = useAuthContext();
  const { params } = useParsed<AcceptPriceRouteParams>();
  const [form] = Form.useForm();

  const { data, isLoading, error } = useCustom<GetAcceptPriceResponse>({
    url: `deal/${params?.dealId}/accept-price-and-register`,
    method: 'get',
    errorNotification: false,
    queryOptions: {
      retry: 0,
    },
  });

  const {
    mutate: signUpMutate,
    isLoading: isLoadingSignup,
  } = useRegister<SignUpParams>({
    mutationOptions: {
      onSuccess(data) {
        setSuccessSignup(data.success);
      },
      onSettled(data, _, variables) {
        const user = data?.data as UserDto;

        if (user?.email && data?.success) {
          form.setFieldsValue({
            address: variables?.address,
            city: variables?.city,
            zipCode: variables?.zipCode,
          });
        }
      },
    },
  });

  const deal = data?.data?.deal;
  const clientInfo = data?.data?.client;
  const valuationExpired = data?.data?.valuationExpired;
  const existingUser = data?.data?.existingClient;
  const isUserLoggedIn = !!user?.email;
  const isDealNotWaitingForPriceAcceptance =
    deal?.status !== DealStatus.WAITING_PRICE_ACCEPTANCE;

  const {
    mutateAsync: acceptPriceMutate,
    isLoading: isLoadingCreate,
    isSuccess,
    isError,
  } = useCreate();

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (error?.statusCode && error?.statusCode > 399) {
    return <ErrorComponent />;
  }

  if (valuationExpired) {
    return <AcceptPriceExpiredError/>
  }

  if (!isUserLoggedIn && existingUser && !successSignup) {
    return <ForbiddenError />;
  }


  return (
    <>
      {isDealNotWaitingForPriceAcceptance ? (
        <AcceptPriceClosedForAcceptanceError />
      ) : isError ? (
        <AcceptPriceError />
      ) : isSuccess ? (
        <Result
          style={{ maxWidth: '660px', margin: '0 auto' }}
          status="success"
          title={t('page.acceptPrice.successMessage')}
          subTitle={t('page.acceptPrice.successSubMessage')}
          extra={[
            <Button key="login" type="primary" href={`/biler/${deal?.id}`}>
              {t('page.acceptPrice.successButton')}
            </Button>,
          ]}
        />
      ) : (
        <>
          {existingUser || successSignup ? (
            <Flex
              style={{ maxWidth: 820, margin: '0 auto', padding: 24 }}
              vertical={true}
              gap={'small'}
              align={'start'}
            >
              <>
                <CarInfo {...data?.data?.deal} />
                <Space
                  direction="horizontal"
                  style={{
                    marginTop: '40px',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Title level={1}>Vi er klar til at sælge din bil</Title>
                </Space>

                <Text>{t('page.acceptPrice.startTitle')}</Text>
                <ol style={{ paddingInlineStart: '40px' }}>
                  <li>{t('page.acceptPrice.startDescription.firstPoint')}</li>
                  <li>{t('page.acceptPrice.startDescription.secondPoint')}</li>
                  <li>{t('page.acceptPrice.startDescription.thirdPoint')}</li>
                </ol>
                <Divider />
                <AcceptPriceGeneralForm
                  form={form}
                  existingUserInfo={existingUser}
                  dealId={params?.dealId}
                  acceptPriceMutate={acceptPriceMutate}
                  isLoadingCreate={isLoadingCreate}
                />
              </>
            </Flex>
          ) : (
            <>
              <StyledAlert
                color={token.colorBgLayout}
                message={
                  <Space>
                    <CarOutlined
                      style={{ fontSize: '24px', color: '#faad14' }}
                    />
                    <Text type="secondary" style={{ color: '#333' }}>
                      Vi er klar til at sælge din bil! Opret en konto og udfyld
                      formularen for at komme i gang.
                    </Text>
                  </Space>
                }
                type="info"
              />
              <AcceptPriceSignupForm
                clientInfo={clientInfo}
                signUpMutate={signUpMutate}
                isLoadingSignup={isLoadingSignup}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const StyledAlert = styled(Alert)<{ color: string }>`
    margin-bottom: 20px;
    background-color: ${props => props.color};
    border-radius: 8px;
    border-color: #faad14;
`;
