import { AndroidOutlined, AppleFilled } from '@ant-design/icons';
import { CheckCard, ProForm } from '@ant-design/pro-components';
import { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { HttpError, useCreate, useCustom, useGo, useParsed, useRegister } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Button, Divider, Flex, Form, Result, Space, Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import Skeleton from '@ant-design/pro-skeleton';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarInfo } from '../../components/car-info';
import { YesNoDontKnowOptionSelector } from '../../components/yes-or-no';
import { ICarSubmissionDetailsV3 } from '../../provider/dto/buyer-submission.dto';
import { BuyerSubmissionDetails } from './vo/route-params';
import { AcceptPriceSignupForm, ForbiddenError } from '../../components/accept-price';
import { SignUpParams } from '../../provider/auth/vo/signup-response.interface';
import { useAuthContext } from '../../contexts/auth';
import { BuyerUpsaleDetails } from './vo/buyer-details';

const { Text, Title } = Typography;


export const BuyerSubmissionPage: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  const [successSignup, setSuccessSignup] = useState(false);
  const { user } = useAuthContext();

  const {
    mutate: signUpMutate,
    isLoading: isLoadingSignup,
  } = useRegister<SignUpParams>({
    mutationOptions: {
      onSuccess(data) {
        setSuccessSignup(data.success);
      },
    },
  });

  useDocumentTitle({ i18nKey: 'page.buyerCarForm.pageMetaTitle' });
  const { mutateAsync, isLoading: isLoadingCreate } = useCreate<BuyerUpsaleDetails>();
  const go = useGo();

  const { t } = useTranslation();

  const { params } = useParsed<BuyerSubmissionDetails>();

  const dealIdFromUrl = useMemo(() => {
    return params?.id;
  }, [params]);

  const { data, isLoading, isError } = useCustom<ICarSubmissionDetailsV3, HttpError>({
    url: `buyer-submission/car-submission-details/v3?dealId=${dealIdFromUrl}`,
    method: 'get',
    errorNotification: false,
    queryOptions: {
      enabled: !!dealIdFromUrl
    }
  });

  if (isLoading && dealIdFromUrl || !data?.data) {
    return <Skeleton type="result" />;
  }

  const buyerDealData = data?.data;
  const deal = buyerDealData?.deal;

  const buyerId = buyerDealData.customerInfo.id;
  const dealId = deal.id;

  const requireRegistration = !buyerDealData.hasProfile;

  if (isError || !dealId || !buyerId) {
    return <Result
      status="500"
      title={t('global.error')}
      subTitle={t('global.unexpectedError')}
      extra={<a href="mailto:hej@aksel.nu">{t('global.contactSupport')}</a>}
    ></Result>;
  }

  if (!user?.email && !requireRegistration && !successSignup) {
    return <ForbiddenError />;
  }

  return (requireRegistration && !successSignup
    ? <AcceptPriceSignupForm
      signUpMutate={signUpMutate}
      isLoadingSignup={isLoadingSignup}
      clientInfo={buyerDealData.customerInfo}
      signupButtonMessage={'page.buyerSubmission.signupButton'}
    />
    : <ProForm
      onFinish={async (values) => {
        await mutateAsync({
          successNotification: () => false,
          resource: `deal/${dealId}/provide-buyer-upsale-details`,
          values: {
            ...values,
            ...{
              buyerId,
              dealId,
            },
          },
        });

        go({ to: `/biler/${deal.id}` });

      }}

      submitter={{
        submitButtonProps: false,
        resetButtonProps: false,
        render: () => [],
      }}
      style={{ maxWidth: 820, margin: '0 auto', padding: 24 }}
    >
      <CarInfo {...buyerDealData?.deal} />
      <Divider></Divider>

      <Flex vertical={true} gap={'middle'}>

      <Text>
        Vi glæder os til at hjælpe dig med at få handlen helt i mål.
      </Text>
      <Text>
        Udover selve handlen har vi også mulighed for at tilbyde dig en unik ejerskifteforsikring, der kan sikre dig i mod uforudsete værkstedsomkostninger det første år.
      </Text>
      <Text>
        Du skal blot trykke Ja nedenfor, så sender vi dig et uforpligtende tilbud.
      </Text>
      </Flex>

      <Divider>Ejerskifteforsikring</Divider>
      <Flex vertical={true} gap={'small'}>
        <Text>Det koster i gennemsnit <Link target={'_blank'}
                                            href={'https://blog.bilbasen.dk/hvad-er-driftsomkostningerne-ved-en-brugt-bil-442390/'}>9.000-10.000
          kr om året i reparationsomkostninger</Link> for en 5 år gammel bil.</Text>
        <Text>Men fra kun <b>4.519 kr</b> kan vi tilbyde en ejerskifteforsikring, der dækker reparationsomkostninger i
          12 mdr, så du undgår uforudsete udgifter.</Text>
        <Text>Der er <b>ingen selvrisiko</b>, frit værkstedsvalg og forsikringen dækker stort set alle komponenter i
          moderne biler, inklusive multimedia, navigation og kommunikationssystem.</Text>
        <Text>Her et par eksempler på typiske reparationspriser:</Text>

        <ul>
          <li>Aircondition: 5.000 kr.</li>
          <li>Tandrem: 7.000 kr.</li>
          <li>Multimediasystem: 8.000 kr.</li>
          <li>Gearkasse: 32.000 kr.</li>
          <li>Motor: 40.000 kr.</li>
          <li>Bakkamera 7.000 kr.</li>
        </ul>

        <Form.Item
          label={t('page.buyerCarForm.interestedInInsurance')}
          name="wantInsuranceOffer"
          rules={[{ required: true, message: t('global.requiredField') }]}
        >
          <CheckCard.Group
            style={{ width: '100%' }}
            size="small"
          >
            <YesNoDontKnowOptionSelector />
          </CheckCard.Group>
        </Form.Item>
      </Flex>

      <Form.Item>
        <Flex justify={'flex-end'}>
          <Button size={'large'} loading={isLoadingCreate} htmlType={'submit'} type={'primary'}
                  >Videre</Button>
        </Flex>
      </Form.Item>

    </ProForm>);
};