import React, { useState } from 'react';
import {
  getDefaultFilter,
  getDefaultSortOrder,
  RefineThemedLayoutV2HeaderProps,
  TagField,
  useTable,
} from '@refinedev/antd';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { useTranslation } from 'react-i18next';
import { HttpError, useGo, useOne, useParsed } from '@refinedev/core';
import { PartnerPortalRouteParams } from './vo/route-params';
import Skeleton from '@ant-design/pro-skeleton';
import { ErrorComponent } from '../error/404';
import { Button, Flex, Form, Input, Space, Table, Tag, theme, Typography } from 'antd';
import { Organisation } from './vo/org';
import { Submission, DealStatus } from './vo/submission';
import { CarOutlined, EyeOutlined } from '@ant-design/icons';
import { debounce } from '../../utils/debouence';
import { AcceptPriceForm } from '../../components/accept-price';

const { useToken } = theme;

const { Search } = Input;

const { Text, Title } = Typography;

export const PartnerPortalPage: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  useDocumentTitle({ i18nKey: 'page.partnerPortal.pageMetaTitle' });

  const { t } = useTranslation();
  const { token } = useToken();
  const go = useGo();

  const { params } = useParsed<PartnerPortalRouteParams>();

  const [search, setSearch] = useState('');

  const {
    isLoading,
    error,
    data,
  } = useOne<Organisation>({
    resource: 'organisation',
    id: params?.organisationId,
    errorNotification: false,
    queryOptions: {
      retry: 0,
    },
  });

  const {
    searchFormProps,
    sorters,
    filters,
    tableProps,
    tableQueryResult
  } = useTable<Submission, HttpError, { search: string }>({
    resource: 'partner-deals/' + params?.organisationId,
    pagination: {
      mode: 'server',
      pageSize: 20,
    },
    meta: {
      search,
    },
    onSearch: (values: { search: string }) => {
      setSearch(values.search);
      return [];
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
    },
    syncWithLocation: false,
    queryOptions: {
      retry: 0,
    },
  });

  if (error?.statusCode && error?.statusCode > 399) {
    return <ErrorComponent />;
  }

  return <>
    {isLoading
      ? <Skeleton type="result" />
      : <Flex
        style={{ maxWidth: 1440, margin: '0 auto', padding: 24 }}
        vertical={true}
        gap={'small'}
        align={'center'}
      >

        <Title level={1}>{data?.data.name}</Title>

        <Flex
          style={{
            width: '100%',
            maxWidth: 1140,
          }}
          align={'center'}
          wrap={'wrap-reverse'}
          gap={'small'}
          justify={'space-between'}>
          <Form {...searchFormProps} layout="inline">
            <Form.Item name="search">
              <Search style={{
                minWidth: '320px',
              }} size={'large'} loading={isLoading} onChange={debounce(searchFormProps.form?.submit, 400)}
                      placeholder={t(t('page.partnerPortal.table.searchByText'))} />
            </Form.Item>
          </Form>

          <Button type={'primary'}
                  onClick={() => go({
                    to: `/partner/${params?.organisationId}/bil`,
                  })}
                  icon={<CarOutlined />}>{t('page.partnerPortal.table.addNewCar')}</Button>
        </Flex>

        <Table
          {...tableProps}
          scroll={{ x: 1140 }}
          locale={{
            emptyText: t('common.noDataText'),
            filterReset: t('common.reset'),
            filterCheckall: t('common.selectAll'),
          }}
          rowKey="id">
          <Table.Column
            width={160}
            dataIndex="vin" title={t('common.vin')}
            ellipsis={true}
          />

          <Table.Column
            width={100}
            ellipsis={true}
            dataIndex="make" title={t('common.carMake')} />
          <Table.Column
            dataIndex="model"
            title={t('common.carModel')}
            width={80}
            ellipsis={true}
          />
          <Table.Column
            dataIndex="year" title={t('common.year')}
            width={60}
            ellipsis={true}

          />
          <Table.Column
            width={60}
            dataIndex="fuel"
            title={t('common.fuel')}
            render={(value: string) => <Tag>{t(`common.fuelTypes.${value}`)}</Tag>}
          />

          <Table.Column
            width={120}
            defaultFilteredValue={getDefaultFilter('status', filters)}
            filterMode={'tree'}
            filters={
              Object.values(DealStatus).filter(s => {
                return s !== DealStatus.CONNECT_SYMBLE_PAY
                  && s !== DealStatus.REDEMPTION_STATEMENT
                  && s !== DealStatus.SELLER_PREPARE_TO_SELL_STAGE
                  && s !== DealStatus.BUYER_CONFIRM_BANK_LIMIT_INCREASE
                  && s !== DealStatus.SECOND_OWNER_DETAILS
                  && s !== DealStatus.BUYER_UPSALE_DETAILS;
              }).map((status) => ({
                text: t(`page.partnerPortal.table.status.${status}`),
                value: status,
              }))}

            dataIndex="status"
            title={t('common.status')}
            render={(value: string) => <TagField
              bordered={false}
              value={t(`page.partnerPortal.table.status.${value}`)}
              color={value === DealStatus.WAITING_PRICE_ACCEPTANCE ? 'orange' : 'blue'}
            />}
          />
          <Table.Column
            width={100}
            dataIndex="createdAt"
            title={t('common.createdAt')}
            sorter
            defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
            showSorterTooltip={false}
            render={(value: string) =>
              <Text>{new Date(value).toLocaleDateString()} {new Date(value).toLocaleTimeString()}</Text>}
          />

          <Table.Column
            width={100}
            dataIndex={'askingPrice'}
            title={t('page.partnerPortal.table.askingPrice')}
            render={(value: string) => value ?
              <Space direction={'horizontal'} wrap={true} size={4} align={'start'}>
                <Text style={{ color: token.colorPrimary }}>
                  DKK
                </Text>
                <Title level={4} style={{ color: token.colorPrimary }}>
                  {isNaN(Number(value)) ? 'N/A' : Number.parseInt(value, 10).toLocaleString('da-DK')},-
                </Title>
              </Space>
              : 'N/A'}
          >

          </Table.Column>

          <Table.Column
            width={120}
            title={t('page.partnerPortal.table.actions')}
            render={(r: Submission) => {
              return <Space direction={'horizontal'} wrap={true}>
                <Button onClick={() => go({
                  to: `/partner/${params?.organisationId}/bil/${r.id}`,
                })} type={'text'} shape={'circle'} icon={<EyeOutlined />} />
                {r.status === DealStatus.WAITING_PRICE_ACCEPTANCE ?
                  <AcceptPriceForm
                    onSuccess={async () => {
                      await tableQueryResult.refetch();
                    }}
                    organisationId={r.organisationId}
                    id={r.id}
                  /> : null}
              </Space>

            }}
          />

        </Table>

      </Flex>}
  </>;
};
