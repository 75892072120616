import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from './App';
import './i18n';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAntdLocale } from './i18n/utils';
import { AuthProvider } from './provider/auth/auth.provider';

const IS_PRODUCTION_MODE = import.meta.env.PROD;

if (IS_PRODUCTION_MODE) {
  Sentry.init({
    dsn: 'https://47f793e9ad2c714c656535f1c0d50c2e@o4507993189318656.ingest.de.sentry.io/4507993194365008',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/api\.garage\.aksel\.nu\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const RootComponent = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  return (
    <React.Suspense fallback="loading">
      <ConfigProvider locale={getAntdLocale(locale)}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ConfigProvider>
    </React.Suspense>
  );
};

root.render(<RootComponent />);
