import { Deal } from '../../../utils/deal-base.dto';

export interface FileUpload {
  originalName: string;
  urlKey: string;
  size: number;
  type: string;
}

export enum DealStatus {
  UNDER_REVIEW = 'UNDER_REVIEW',
  LEAD_REJECTED = 'LEAD_REJECTED',
  WAITING_PRICE_ACCEPTANCE = 'WAITING_PRICE_ACCEPTANCE',
  WAITING_FOR_IMAGES = 'WAITING_FOR_IMAGES',
  ADVERTISEMENT_CREATED = 'ADVERTISEMENT_CREATED',
  ADVERTISEMENT_LIVE = 'ADVERTISEMENT_LIVE',
  SHOWING_TO_BUYER = 'SHOWING_TO_BUYER',
  SELLER_PREPARE_TO_SELL_STAGE = 'SELLER_PREPARE_TO_SELL_STAGE',
  SELL_AGREED = 'SELL_AGREED',
  CONNECT_SYMBLE_PAY = 'CONNECT_SYMBLE_PAY',
  REDEMPTION_STATEMENT = 'REDEMPTION_STATEMENT',
  SECOND_OWNER_DETAILS = 'SECOND_OWNER_DETAILS',
  BUYER_UPSALE_DETAILS = 'BUYER_UPSALE_DETAILS',
  BUYER_CONFIRM_BANK_LIMIT_INCREASE = 'BUYER_CONFIRM_BANK_LIMIT_INCREASE',
  SIGNING_CONTRACT = 'SIGNING_CONTRACT',
  PAYMENT = 'PAYMENT',
  CONFIRM_OWNERSHIP_CHANGE = 'CONFIRM_OWNERSHIP_CHANGE',
  SOLD = 'SOLD',
  INACTIVE = 'INACTIVE',
}

export function nullOrUndefined(value: unknown) {
  return value === null || value === undefined;
}

export function findStatusOrder(deal: Deal, seller: boolean, buyer: boolean) {

  if (deal.status === DealStatus.SELL_AGREED) {
    if (seller) {
      if (
        nullOrUndefined(deal.secondSellerDetails) &&
        nullOrUndefined(deal.sellerSymblePayAccId) &&
        nullOrUndefined(deal.sellerSymblePayAccDetails) &&
        ((deal.debt && nullOrUndefined(deal.redemptionDetails)) || !deal.debt)
      ) {
        return getStatusOrder(DealStatus.SELLER_PREPARE_TO_SELL_STAGE);
      }

    }

    if(buyer) {
      if (
        nullOrUndefined(deal.secondBuyerDetails) &&
        nullOrUndefined(deal.buyerSymblePayAccId) &&
        nullOrUndefined(deal.buyerSymblePayAccDetails) &&
        !deal.buyerConfirmedLimitIncrease
      ) {
        return getStatusOrder(DealStatus.BUYER_CONFIRM_BANK_LIMIT_INCREASE);
      }
    }
  }

  return getStatusOrder(deal.status);

}

export function getStatusOrder(status?: DealStatus) {
  switch (status) {
    case DealStatus.UNDER_REVIEW:
      return 100;
    case DealStatus.WAITING_PRICE_ACCEPTANCE:
      return 200;
    case DealStatus.WAITING_FOR_IMAGES:
      return 300;
    case DealStatus.ADVERTISEMENT_CREATED:
      return 400;
    case DealStatus.ADVERTISEMENT_LIVE:
      return 500;
    case DealStatus.SHOWING_TO_BUYER:
      return 600;
    case DealStatus.SELLER_PREPARE_TO_SELL_STAGE:
      return 650;
    case DealStatus.SELL_AGREED:
      return 700;
    case DealStatus.CONNECT_SYMBLE_PAY:
      return 750;
    case DealStatus.REDEMPTION_STATEMENT:
      return 800;
    case DealStatus.BUYER_UPSALE_DETAILS:
      return 825;
    case DealStatus.BUYER_CONFIRM_BANK_LIMIT_INCREASE:
      return 837;
    case DealStatus.SECOND_OWNER_DETAILS:
      return 850;
    case DealStatus.SIGNING_CONTRACT:
      return 900;
    case DealStatus.PAYMENT:
      return 1000;
    case DealStatus.CONFIRM_OWNERSHIP_CHANGE:
      return 1050;
    case DealStatus.SOLD:
      return 1100;
    case DealStatus.INACTIVE:
      return -1;
    default:
      return 0;
  }
}

export interface Submission {
  id: string
  vin: string
  make: string
  model: string
  mileage: number
  year: number
  color: string
  gear: string
  askingPrice: number
  status: DealStatus
  fuel: string
  organisationId: string
  images: FileUpload[]
  version: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
}
