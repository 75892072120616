import { HttpError, useCreate, useUpdate } from '@refinedev/core';
import React, { useState } from 'react';
import { Deal } from '../../utils/deal-base.dto';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { useTable } from '@refinedev/antd';
import Skeleton from '@ant-design/pro-skeleton';
import { Button, Flex, Form, Input, Space, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../utils/debouence';
import { ModalForm, ProForm, ProFormText } from '@ant-design/pro-components';
import { BusinessAccount, CreateBusinessAccount } from './vo/business-account.vo';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { addTrailingZerosToStart, allowOnlyNumbers } from '../../utils/number';

export const BusinessAccountsPage = () => {
  useDocumentTitle({ i18nKey: 'page.admin.business-accounts.list.pageMetaTitle' });

  const [ editRecord, setEditRecord ] = useState<BusinessAccount>();

  const { mutateAsync: createAcc, isLoading: isCreatingAccount } = useCreate();
  const { mutateAsync: updateAcc, isLoading: isUpdatingAccount} = useUpdate();


  const { Text, Title } = Typography;
  const { Search } = Input;
  const [form] = Form.useForm<CreateBusinessAccount>();

  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const tableData = useTable<Deal, HttpError, { search: string }>({
    resource: 'symblepay-business-account',
    pagination: {
      mode: 'server',
      pageSize: 20,
    },
    meta: {
      search: search.trim(),
    },
    onSearch: (values: { search: string }) => {
      setSearch(values.search);
      return [];
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
    },
    syncWithLocation: true,
    queryOptions: {
      retry: 0,
    },
  });

  const isLoading = tableData.tableQueryResult.isLoading;

  return <Flex>
    {isLoading ? (
        <Skeleton type="result" />
      ) :
      <Flex
        style={{ maxWidth: '100%', margin: '0 auto', padding: 24 }}
        vertical={true}
        gap={'small'}
        align={'center'}
      >
        <Title level={1}>{t('page.admin.business-accounts.list.title')}</Title>

        <Flex
          justify={'space-between'}
          wrap={'wrap-reverse'}
          style={{
            maxWidth: 1140,
            width: '100%',
          }}
        >
          <Form {...tableData.searchFormProps} layout="inline">
            <Form.Item name="search">
              <Search style={{
                minWidth: '320px',
              }} size={'large'} loading={isLoading} onChange={debounce(tableData.searchFormProps.form?.submit, 400)}
                      placeholder={t('page.admin.business-accounts.list.searchBy')} />
            </Form.Item>
          </Form>
          <ModalForm<CreateBusinessAccount>
            loading={isCreatingAccount || isUpdatingAccount}
            title={t('global.add') + ' ' + t('page.admin.business-accounts.list.title')}
            trigger={
              <Button type="primary">
                <PlusOutlined />
                {t('global.add')}
              </Button>
            }
            open={!!editRecord}
            form={form}
            autoFocusFirstInput
            modalProps={{
              destroyOnClose: true,
              onCancel: () => {
                setEditRecord(undefined);
                form.resetFields();
              }
            }}
            onFinish={async (values) => {
              if (editRecord) {
                await updateAcc({
                  id: editRecord.id,
                  resource: `symblepay-business-account`,
                  values
                });
              } else {
                await createAcc({
                  resource: 'symblepay-business-account',
                  values: {
                    ...values,
                    registrationNumber: (values.registrationNumber + '')?.trim()?.toLowerCase(),
                    accountNumber: (addTrailingZerosToStart(values.accountNumber + '', 10))?.trim()?.toLowerCase(),
                  }
                });
              }

              setEditRecord(undefined);
              form.resetFields();

              return true;
            }}
          >
            <ProForm.Group>
              <ProFormText
                width="md"
                name="name"
                label="Navn"
                placeholder="Danmarks Bank"
                rules={[
                  { required: true, message: t('global.requiredField') },
                  {
                    max: 200,
                    message: t('global.maxLength', { max: 200 }),
                  },
                ]}
              />

              <ProFormText
                width="md"
                name="cvrNumber"
                label="CVR"
                placeholder="2012931234"
                disabled={!!editRecord}
                rules={[
                  {
                    required: true, message: t('global.requiredField'),
                  },
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />
              <ProFormText
                width="md"
                name="pNumber"
                disabled={!!editRecord}
                label="P code"
                placeholder="12345678"
                rules={[{ required: true, message: t('global.requiredField') },
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />
              <ProFormText
                width="md"
                name="phoneNumber"
                label="Telefonnummer"
                placeholder="12345678"
                rules={[{ required: true, message: t('global.requiredField') },
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />

              <ProFormText
                width="md"
                name="registrationNumber"
                label={t('redemption.registrationNumber')}
                placeholder={'1234'}
                fieldProps={{
                  maxLength: 4,
                  minLength: 4,
                  showCount: true,
                  onKeyPress: allowOnlyNumbers,
                }}
                rules={[
                  { required: true, message: t('global.requiredField') },
                  {
                    min: 4, message: t('global.minLength', { min: 4})
                  }
                ]}
              />

              <ProFormText
                width="md"
                name="accountNumber"
                label={t('redemption.accountNumber')}
                placeholder={'0012345678'}
                fieldProps={{
                  maxLength: 10,
                  showCount: true,
                  onKeyPress: allowOnlyNumbers,
                }}
                rules={[
                  { required: true, message: t('global.requiredField') },
                ]}
              />

              <ProFormText
                width="md"
                name="streetName"
                label="Vejnavn"
                placeholder="Indtast vejnavn"
                rules={[{ required: true, message: t('global.requiredField') },
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />
              <ProFormText
                width="md"
                name="houseNumber"
                label="Husnummer"
                placeholder="Indtast husnummer"
                rules={[{ required: true, message: t('global.requiredField') },
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />
              <ProFormText
                width="md"
                name="city"
                label="By"
                placeholder="Indtast by"
                rules={[{ required: true, message: t('global.requiredField') },
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />
              <ProFormText
                width="md"
                name="postNumber"
                label="Postnummer"
                placeholder="Indtast postnummer"
                rules={[{ required: true, message: t('global.requiredField') },
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />
              <ProFormText
                width="md"
                name="municipalityName"
                label="Kommune"
                placeholder="Indtast kommunenavn"
                rules={[{ required: true, message: t('global.requiredField') },
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />

              <ProFormText
                width="md"
                name="floor"
                label="Etage"
                placeholder="Indtast etagenummer"
                rules={[
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />
              <ProFormText
                width="md"
                name="sideDoor"
                label="Sidedør"
                placeholder="Indtast sidedør"
                rules={[
                  {
                    max: 64,
                    message: t('global.maxLength', { max: 64 }),
                  },
                ]}
              />
            </ProForm.Group>
          </ModalForm>
        </Flex>

        <Table
          {...tableData.tableProps}
          scroll={{ x: 1140 }}
          locale={{
            emptyText: t('common.noDataText'),
            filterReset: t('common.reset'),
            filterCheckall: t('common.selectAll'),
          }}
          rowKey="id">
          <Table.Column
            width={40}
            dataIndex="name" title={'Navn'}
            ellipsis={true}
          />
          <Table.Column
            width={40}
            dataIndex="cvrNumber" title={'CVR'}
            ellipsis={true}
          />
          <Table.Column
            width={40}
            dataIndex="pNumber" title={'P nummer'}
            ellipsis={true}
          />
          <Table.Column
            width={60}
            dataIndex="createdAt"
            title={t('common.createdAt')}
            sorter
            showSorterTooltip={false}
            render={(value: string) => (
              <Text>
                {new Date(value).toLocaleDateString()}{' '}
                {new Date(value).toLocaleTimeString()}
              </Text>
            )}
          />
          <Table.Column
            width={50}
            title={t('page.admin.deal.list.fields.details')}
            render={(record: BusinessAccount) => {
              return (
                <Space direction={'horizontal'} wrap={true}>
                  <Button
                    onClick={() => {
                      form.setFieldsValue(record);
                      setEditRecord(record);
                    }}
                    type={'text'}
                    shape={'circle'}
                    icon={<EditOutlined />}
                  />
                </Space>
              );
            }}
          />
        </Table>
      </Flex>}
  </Flex>;
};
