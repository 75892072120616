import { FileUpload } from '../provider/dto/file-upload.dto';
import { DealStatus } from '../pages/partner/vo/submission';
import { CarUsageDetails } from '../components/deal-details/upload-images/vo';
import { SymblePayAccountDetails, TradeDetails } from '../pages/deal-details/vo/symble-pay.vo';
import { BuyerUpsaleDetails } from '../pages/buyer-submission/vo/buyer-details';

export enum Fuel {
  GAS = 'GAS',
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRIC',
  HYBRID = 'HYBRID',
  FGAS = 'FGAS',
  PETROLEUM = 'PETROLEUM',
  NITROGEN = 'NITROGEN',
  HYDROGEN = 'HYDROGEN',
  ETHANOL = 'ETHANOL',
}

export enum Gear {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export function isElectric(fuel?: string): boolean {
  return fuel === Fuel.ELECTRIC || fuel === Fuel.HYBRID;
}

export interface Deal {
  id: string
  licensePlate: string
  make: string
  model: string
  variant: string
  year: number
  registration: string
  getPipedriveDealId: number
  getSellerName: string
  getCaradsId: number
  getSellerEmail: string
  lastInspectionDate: string
  mileage: number
  fuel: Fuel
  type: string
  color: string
  preferredShowingTimeWeekday?: string[]
  preferredShowingTimeWeekend?: string[]
  symblePayTradeId?: string;
  damaged: boolean
  debt: boolean
  financing?: boolean
  images: FileUpload[]
  paymentReceipt: FileUpload
  usageDetails: CarUsageDetails
  organisationId: string
  damagedDetails: string
  takeOverDate?: string
  status: DealStatus
  vin: string
  buyerConfirmedLimitIncrease?: boolean
  equipmentFreeText: string
  askingPrice?: number
  buyerUpsaleDetails?: BuyerUpsaleDetails
  sellerValuation: number
  condition: string
  gear: Gear
  sellerId: string
  buyerId: string
  version: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
  redemptionDetails?: RedemptionDetails
  secondBuyerDetails?: SecondOwnerDetails
  secondSellerDetails?: SecondOwnerDetails
  sellerSymblePayAccId?: string;
  buyerSymblePayAccId?: string;
  sellerSymblePayAccDetails?: SymblePayAccountDetails;
  buyerSymblePayAccDetails?: SymblePayAccountDetails;
  tradeSymblePayDetails?: TradeDetails;
  sellerConfirmedCarOwnershipTransfer?: boolean;
  penneoSalesContractSignInformation?: SignFileDetails;
}

export interface SignFileDetails {
  documentId: number;
  completed: boolean;
  rejected: boolean;
  signedContract?: FileUpload;
  firstSeller: Required<UserToSignDetails>;
  firstBuyer: Required<UserToSignDetails>;
  secondSeller?: UserToSignDetails;
  secondBuyer?: UserToSignDetails;
}

export interface UserToSignDetails {
  userId?: string;
  signerLineId: number;
  signerId: number;
  completed: boolean;
  rejected: boolean;
  viewed: boolean;
  url: string;
}



export interface RedemptionDetails {
  businessAccountId?: string;
  amount: number;
  accountNumber?: string;
  fiKort: boolean;
  registrationNumber?: string;
  fiKortCreditor?: string;
  fiKortPaymentId?: string;
  redemptionDate: string;
  referenceNumber?: string;
  files: FileUpload[];
  approved?: boolean;
  approvedBy?: string;
  rejectionReason?: string;
}

export interface RedemptionApprovalRequest {
  approved: boolean;
  rejectionReason?: string;
}

export interface SecondOwnerDetails {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  zipCode: number;
}
