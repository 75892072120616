import { SEASONS } from '../car-questionnaire/tires.question.form';
import { AnswerType } from '../vo';

export const mapToAnswerType = (value: boolean | 'dontKnow'): AnswerType => {
  switch (value) {
    case true:
      return AnswerType.YES;
    case false:
      return AnswerType.NO;
    case 'dontKnow':
      return AnswerType.NOT_SURE;
    default:
      throw new Error('mapToAnswerType: Not Mapped, contact developers');
  }
};

function mapAnswerTypeToDanish(answerType: string): string {
  switch (answerType) {
    case 'YES': {
      return 'Ja';
    }
    case 'NO': {
      return 'Nej';
    }
    case 'NOT_SURE': {
      return 'Ved ikke';
    }
    default: {
      return 'N/A';
    }
  }
}

export function mapMotorChangeAnswer(answer: string, details?: string): string {
  const text = mapAnswerTypeToDanish(answer);
  if (answer === 'YES') {
    switch (details) {
      case 'NEW':
        return `${text} - med Fabriksny`;
      case 'USED':
        return `${text} - med Brugt`;
      case 'RENOVATED':
        return `${text} - med Renoveret`;
      default:
        return text;
    }
  }

  return text;
}

export function mapRepaintedAnswer(answer: string, details?: string): string {
  const text = mapAnswerTypeToDanish(answer);
  return `${text}${details ? ' - ' + details : ''}`;
}

export function mapDamagedAnswer(answer: string, details?: string): string {
  const text = mapAnswerTypeToDanish(answer);
  return `${text}${details ? ' - ' + details : ''}`;
}

export function mapChipTunedAnswer(answer: string, details?: boolean): string {
  const text = mapAnswerTypeToDanish(answer);
  return `${text}${details !== undefined
    ? ' - dokumentation: ' +
    (details
      ? 'Ja'
      : details === false
        ? 'Nej'
        : 'Ved ikke')
    : ''}`;
}

export function mapTimeBeltAnswer(answer: string, details?: number): string {
  const text = mapAnswerTypeToDanish(answer);
  return `${text}${details !== undefined
    ? ' - ved ' + details + ' km'
    : ''}`;
}

export function mapServiceInTimeAnswer(answer: string): string {
  switch (answer) {
    case 'YES': {
      return 'Ja';
    }
    case 'PARTIALLY': {
      return 'Delvist';
    }
    case 'NO_INFO': {
      return 'Ingen dokumentation';
    }
    default: {
      return 'N/A';
    }
  }
}

export function mapCarTireTypeAnswer(answer: keyof typeof SEASONS): string {
  switch (answer) {
    case 'SUMMER': {
      return 'Kun sommerdæk';
    }
    case 'WINTER': {
      return 'Kun vinterdæk';
    }
    case 'BOTH': {
      return 'Både sommer-og vinterdæ';
    }
    case 'ALL_SEASON': {
      return 'Helårsdæk';
    }
    default: {
      return 'N/A';
    }
  }
}